class FooterSitemap extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const breakpoint = "650px";
    const widthMediaQuery = window.matchMedia(`(min-width: ${breakpoint})`);

    this.updateState(widthMediaQuery);

    widthMediaQuery.addListener((data) => {
      this.updateState(data);
    });
  }

  updateState(data) {
    if (data.matches) { // is Large
      this.querySelectorAll(".sitemap-group").forEach((e) => { return e.setAttribute("open", ""); });
    } else { // is Small
      this.querySelectorAll(".sitemap-group").forEach((e) => { return e.removeAttribute("open"); });
    }
  }
}
if (!customElements.get('footer-sitemap')) {
  customElements.define('footer-sitemap', FooterSitemap);
}
